'use client';

import { redirect } from 'next/navigation';
import { signIn, useSession } from 'next-auth/react';

export default function Index() {
  const { status } = useSession<any>();

  if (status === 'authenticated') {
    redirect('/en/home/marketplace');
  } else if (status !== 'loading') {
    signIn();
  }
}
